body {
  font-family: 'Maven Pro', sans-serif;
  color: black;
}
.App {
  text-align: center;
}


.App-left {
  background-color: white;
  width: 50%;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: scroll;
}
.App-right {
  background-color: black;
  width: 50%;
  height: 100vh;
  float: right;  
}
@media only screen and (max-width: 600px) {
  .App-left {
  background-color: white;
  width: 100%;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: scroll;
}
.App-right {
  background-color: black;
  width: 100%;
  height: 100vh;
  float: right;  
}
}
.App-txt {
  font-size: 25pt;
  margin: 10vh;
  width: 100%;
}
.App-txt-hightlight {
  font-weight: 900;
  font-size: 35pt;  
}
.App-txt-headline {
  font-weight: 900;
  font-size: 15pt;
  width: 100%;
}
.App-txt-summary {
  margin: 5vh;
  width: 100%;
}
.App-logo {
  width: 100%;
  font-weight: 900;
  font-size: 45pt;
  margin: 0;
}
.Bg-img {
  object-fit: contain;
  width: 70%;
  -webkit-filter: grayscale(100%);
}
.App-links {
  width: 100%;
  align-content: center;
  margin: 5vh;
}
.App-link-img {
  width: 30px;
  height: 30px;
}
